<template>
  <vc-date-picker
    is-range
    v-model="dateModel"
    :mode="withTime ? 'dateTime' : 'date'"
    :locale="lang"
    is24hr
    :masks="masks"
    :modelConfig="modelConfig"
    :minute-increment="minutesIncrement"
    :input-debounce="500"
    :update-on-input="true"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        id="start"
        :placeholder="placeholder !== '' ? placeholder : examplePlaceholder"
        class="form-control half-width-left-form"
        :value="inputValue.start"
        v-on="inputEvents.start"
      />
      <b-icon
        icon="chevron-right"
        scale="2"
        shift-v="-12"
        shift-h="19"
      ></b-icon>
      <input
        id="end"
        :placeholder="placeholder !== '' ? placeholder : examplePlaceholder"
        class="form-control half-width-right-form"
        :value="inputValue.end"
        v-on="inputEvents.end"
      />
    </template>
  </vc-date-picker>
</template>

<script>
// https://vcalendar.io/

import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "RangeDatePicker",
  props: {
    withTime: {
      type: Boolean,
      default: false,
    },
    minutesIncrement: {
      type: Number,
      default: 5,
    },
    placeholder: {
      type: String,
      default: "",
    },
    // использовать :date.sync
    startDate: {
      default: null,
    },
    endDate: {
      default: null,
    },
  },
  data() {
    return {
      masks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
      modelConfig: {
        type: "string",
        mask: "iso",
        timeAdjust: "00:00:00",
      },
    };
  },
  computed: {
    dateModel: {
      get() {
        return {
          start: this.startDate
            ? new Date(this.startDate)
            : this.endDate
            ? new Date()
            : null,
          end: this.endDate
            ? new Date(this.endDate)
            : this.startDate
            ? new Date()
            : null,
        };
      },
      set(value) {
        if (value) {
          this.$emit("update:startDate", value.start);
          this.$emit("update:endDate", value.end);
        }
      },
    },

    lang() {
      return i18nService.getActiveLanguage();
    },

    examplePlaceholder() {
      const options = {};
      options.day = "2-digit";
      options.month = "2-digit";
      options.year = "numeric";

      if (this.withTime) {
        options.hour = "2-digit";
        options.minute = "2-digit";
      }

      return (
        this.$t("COMMON.EXAMPLE") +
        ": " +
        new Intl.DateTimeFormat(this.lang, options)
          .format(new Date())
          .split(",")
          .join("")
      ); // костыль для удаления запятой
    },
  },
};
</script>

<style scoped>
.half-width-left-form {
  display: inline-block;
  width: calc(50% - 20px);
  float: left;
}
.half-width-right-form {
  display: inline-block;
  width: calc(50% - 20px);
  float: right;
}
</style>
