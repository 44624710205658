<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-2">
            <b-form-input
              size="md"
              aria-label="Unique Id"
              :placeholder="$t('MESSAGES.UNIQUE_ID')"
              v-model="filterUniqueId"
              trim
            ></b-form-input>

            <CompactSuggestion
              v-if="isAdmin"
              v-model="filterTeamId"
              :placeholder="$t('COMMON.TEAM')"
              suggestion-processor="teamSuggestionProcessor"
              style="min-width: 30%"
            ></CompactSuggestion>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Search"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>

          <b-input-group class="mb-2">
            <CompactSuggestion
              v-model="filterDeviceId"
              :placeholder="$t('MESSAGES.DEVICE')"
              ref="deviceSuggestionProcessorRef"
              suggestion-processor="deviceSuggestionProcessor"
              :withoutTeamId="isAdmin"
              style="min-width: 30%"
            ></CompactSuggestion>

            <b-form-select
              v-model="filterMessageType"
              :options="messageTypes"
              size="md"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t("MESSAGES.SELECT_MESSAGE_TYPE") }}
                </option>
              </template>
            </b-form-select>

            <range-date-picker
              id="field-date"
              :start-date.sync="startDate"
              :end-date.sync="endDate"
              with-time
            ></range-date-picker>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          responsive="sm"
          class="text-wrap"
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          :fields="isAdmin ? adminFields : fields"
        ></b-table>
        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import Common from "../../common";
import ApiService from "@/core/services/api.service";
import RangeDatePicker from "../component/RangeDatePicker";
import CompactSuggestion from "../component/CompactSuggestion";
import { mapState } from "vuex";

export default {
  name: "messages",
  components: {
    CompactSuggestion,
    RangeDatePicker,
  },
  data() {
    return {
      fields: [
        {
          key: "uniqueId",
          label: "Id",
          sortable: false,
          formatter: Common.idObjectFormatter,
        },
        {
          key: "measured",
          label: this.$t("MESSAGES.MEASURED"),
          formatter: Common.idObjectFormatter,
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.measured",
        },
        {
          key: "deviceName",
          label: this.$t("MESSAGES.DEVICE"),
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.device.id",
        },
        {
          key: "messageTypeID",
          label: this.$t("MESSAGES.MESSAGE_TYPE"),
          formatter: this.typeFormatter,
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.messageType.id",
        },
        { key: "data", label: this.$t("MESSAGES.DATA") },
      ],

      adminFields: [
        {
          key: "uniqueId",
          label: "Id",
          sortable: false,
          formatter: Common.idObjectFormatter,
        },
        {
          key: "measured",
          label: this.$t("MESSAGES.MEASURED"),
          formatter: Common.idObjectFormatter,
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.measured",
        },
        {
          key: "deviceName",
          label: this.$t("MESSAGES.DEVICE"),
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.device.id",
        },
        {
          key: "messageTypeID",
          label: this.$t("MESSAGES.MESSAGE_TYPE"),
          formatter: this.typeFormatter,
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.messageType.id",
        },
        // {
        //   key: "teamName",
        //   label: this.$t("COMMON.TEAM"), расскоментировать когда будет в дто сообщений teamName
        //   sortable: false,
        // },
        { key: "data", label: this.$t("MESSAGES.DATA") },
      ],

      filterName: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/message",
        requestType: "GET",
        requestParams: { enrich: true, teamId: ApiService.teamData.value },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,
      calculate: false,
      selectedItem: null,

      messageTypes: [],
      messageTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.MessageType",
        requestType: "GET",
        requestParams: {},
      },
      filterMessageType: "",
      filterDeviceId: null,
      filterUniqueId: "",

      startDate: "",
      endDate: "",

      messageTypesMap: {},
      filterTeamId: null,
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isAdmin() {
      return this.user && this.user.grants.includes("SYSTEM:ADMIN");
    },
  },

  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.MESSAGES") }]);
    // this.$store.dispatch(SET_ACTIONS, [
    //     { label: "Create new", action: this.onCreateNewAction, icon: "fas fa-plus"  },
    //     { label: "Edit", action: this.onEditAction, disabled: function() { return that.selectedItem === null || that.showTableOverlay; }, icon: "fas fa-edit"  },
    //     { label: "Delete", action: this.onDeleteAction, disabled: function() { return that.selectedItem === null || that.showTableOverlay; }, icon: "fas fa-trash-alt" },
    //     { label: "Reload", action: this.onReloadAction, icon: "fas fa-sync" }
    // ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    if (this.isAdmin) {
      delete this.getList.requestParams.teamId;
    }
    this.loadMessageTypes();
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadMessageTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.messageTypesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.messageTypesMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.messageTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onDeviceSelected: function (value) {
      this.selectedDevice = value.id;
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "device" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "device",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/api/device/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    getLang() {
      return localStorage.getItem("language") || "en";
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";
      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;

      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams["uniqueId"] =
        this.filterUniqueId.length > 0 ? this.filterUniqueId : null;
      this.getList.requestParams["deviceId"] = this.filterDeviceId
        ? this.filterDeviceId
        : null;
      this.getList.requestParams["messageTypeId"] =
        this.filterMessageType != "" ? this.filterMessageType : null;
      this.getList.requestParams["startDate"] = this.startDate
        ? this.startDate
        : null;
      this.getList.requestParams["endDate"] = this.endDate
        ? this.addDays(this.endDate, 1).toISOString()
        : null;

      if (this.isAdmin && this.filterTeamId) {
        this.getList.requestParams["teamId"] = this.filterTeamId;
      } else if (this.isAdmin) {
        delete this.getList.requestParams["teamId"];
      }

      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["uniqueId"];
      this.filterUniqueId = "";

      delete this.getList.requestParams["deviceId"];
      this.filterDeviceId = null;
      this.$refs.deviceSuggestionProcessorRef.clearValue();

      delete this.getList.requestParams["messageTypeId"];
      this.filterMessageType = "";

      delete this.getList.requestParams["startDate"];
      this.startDate = "";

      delete this.getList.requestParams["endDate"];
      this.endDate = "";

      if (this.isAdmin) {
        delete this.getList.requestParams["teamId"];
        this.filterTeamId = null;
      }

      this.loadList();
    },

    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    typeFormatter: function (value) {
      if (value && value in this.messageTypesMap)
        return this.messageTypesMap[value].nameLocalized;

      return "";
    },
  },
};
</script>

<style>
td:last-child {
  word-break: break-all;
}
</style>
